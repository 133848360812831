import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppAbstract } from 'src/app/app.abstract';
import { EtapaEnum } from 'src/app/enuns/etapa.enum';
import { DadosInterface } from 'src/app/models/dados.interface';
import { AtencaoComponent } from '../../../modals/atencao/atencao.component';
import { MensagemComponent } from '../../../modals/mensagem/mensagem.component';
import { ProgressoComponent } from '../../../modals/progresso/progresso.component';

@Component({
  selector: 'ftc-veiculo',
  templateUrl: './veiculo.component.html',
  standalone: true,
  imports: [
    AtencaoComponent,
    FormsModule,
    MensagemComponent,
    NgClass,
    NgFor,
    NgIf,
    NgxMaskModule,
    ProgressoComponent,
    ReactiveFormsModule,
  ],
})
export class VeiculoComponent extends AppAbstract implements OnInit {
  dados: DadosInterface = {} as DadosInterface;

  formVeiculo!: FormGroup;

  openModalProgresso: boolean = false;
  submetido: boolean = false;

  modalMensagem: number = 0;
  modalAtencao: number = 0;

  retry: any = 0;
  retryModel: any = 0;
  listaMarcas: any[] = [];
  listaModelos: any[] = [];
  listaAnosFabricacao: any[] = [];

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.criarFormulario();
  }

  private criarFormulario(): void {
    this.formVeiculo = this._formBuilder.group({
      marca: [this.dados.veiculo?.marca?.marcaId || '', Validators.required],
      placa: [this.dados.veiculo?.placa || '', this._veiculoService.validarPlaca],
      model: [this.dados.veiculo?.modelo?.modeloId || '', Validators.required],
      ano: [this.dados.veiculo?.anoFabricacao || '', Validators.required],
      dispositivoCliente: [this.dados.veiculo?.dispositivoCliente]
    });

    this.getMarcas();
  }

  private async getMarcas(): Promise<void> {
    try {
      this.openModalProgresso = true;

      const payload = this._payloadService.payloadMarcas();
      const resposta = await this._carService.getMarcas(payload).toPromise();
      const { listaMarcasVeiculo } = resposta;

      this._logService.setStorageUltimaApi('veiculo/marcas', resposta.serviceMessage?.codigoRetorno, "");
      await this._logService.postLog();

      this.openModalProgresso = false;

      if (listaMarcasVeiculo) {
        this.listaMarcas = listaMarcasVeiculo;
        this.listaMarcas.sort((a, b) => a.descricao.localeCompare(b.descricao));
      } else {
        this.modalAtencao = 6;
      }

      if (this.dados.veiculo?.modelo) this.getModelos();
    } catch (error: any) {
      if (error?.status == 504) {
        console.error('timedout');
        if (this.retry == 3) {
          this.openModalProgresso = false;
          this.modalAtencao = 9;
        } else {
          this.retry++;
          this.getMarcas();
        }

      }
    }
  }

  protected async getModelos(): Promise<void> {
    try {
      this.openModalProgresso = true;

      const item = this.formVeiculo.value.marca;
      const payload = this._payloadService.payloadModelos('1', item);
      const payload2 = this._payloadService.payloadModelos('2', item);

      const resposta = await this._carService.getModelos(payload).toPromise();
      const resposta2 = await this._carService.getModelos(payload2).toPromise();

      this._logService.setStorageUltimaApi('veiculo/modelos', resposta.serviceMessage?.codigoRetorno, "");
      await this._logService.postLog();

      if (resposta?.listaModeloVeiculo && resposta2?.listaModeloVeiculo) {
        const modelosCombinados = [
          ...resposta.listaModeloVeiculo,
          ...resposta2.listaModeloVeiculo
        ];

        const modelosUnicos = this._utilService.removerDuplicados(modelosCombinados);
        const modelosOrdenados = modelosUnicos.sort((a, b) => a.descricao.localeCompare(b.descricao));
        this.listaModelos = this._utilService.removerObjetosVazios(modelosOrdenados);
      }

      this.openModalProgresso = false;
      if (this.dados.veiculo?.anoFabricacao) this.getListaAnos();
    } catch (error: any) {
      if (error?.status == 504) {
        console.error('timedout');
        if (this.retryModel == 3) {
          this.openModalProgresso = false;
          this.modalAtencao = 9;
        } else {
          this.retryModel++;
          this.getModelos();
        }

      }
    }
  }

  protected getListaAnos(): void {
    const veiculo = this.formVeiculo.value.model;
    const item = this.listaModelos.find(
      (item) => item.identificadorModeloVeiculo == veiculo
    );

    this.listaAnosFabricacao = [];

    for (let ano = item.anoLancamento; ano <= item.anoEncerramento; ano++) {
      this.listaAnosFabricacao.push(ano.toString());
    }
  }

  protected async continuar(): Promise<void> {
    this.submetido = true;

    if (this.formVeiculo.valid) {
      this.openModalProgresso = true;

      try {
        await this.postCreditoPlaca();
      } catch (error) {
        this.fecharModal();
        this.modalAtencao = 6;
      }
    }
  }

  private async postCreditoPlaca(): Promise<void> {
    const cpf = this.dados.usuario?.cpf!.replace(/\D/g, '');
    const placa = this.formVeiculo.value.placa.replace(/[-\s]/g, '').toUpperCase();
    const payload = this._payloadService.payloadCreditoPlaca(cpf!, placa);
    const resposta = await this._veiculoService.postCreditoPlaca(payload).toPromise();
    const { codigo } = resposta.resultadoAnaliseCredito;
    const status = resposta.status;

    this._logService.setStorageUltimaApi('credito/placa', status.codigoRetorno, status.mensagem);
    this.criptografarVeiculo();
    await this._logService.postLog();

    this.openModalProgresso = false;

    codigo === 'A'
      ? this.postValidarPlaca(cpf!, placa)
      : this.modalAtencao = 6;
  }

  private async postValidarPlaca(cpf: string, placa: string): Promise<void> {
    this.openModalProgresso = true;

    const payload = this._payloadService.payloadValidarPlaca(cpf, placa);
    const resposta = await this._veiculoService.postValidarPlaca(payload).toPromise();
    const codigo = resposta.status.codigoRetorno;
    const status = resposta.status;

    this._logService.setStorageUltimaApi('validar/placa', status.codigoRetorno, status.mensagem);
    await this._logService.postLog();

    this.openModalProgresso = false;

    if (codigo === 0) {
      this._etapaService.definirEtapa(EtapaEnum.ENDERECO);
    } else if (codigo === '-1002' || codigo === '-1003' || codigo === '-1005') {
      this.modalAtencao = 4;
    } else if (codigo === '-1004' || codigo === '-1006' || codigo === '-1008' || codigo == '-1007') {
      this.modalMensagem = 4;
    } else {
      this.fecharModal();
      this.modalAtencao = 6;
    }
  }

  protected fecharModal(): void {
    this.modalAtencao = this.modalMensagem = 0;
    this.openModalProgresso = false;
  }

  private criptografarVeiculo(): void {
    const marca = this._carService.getItemListaMarcas(
      this.formVeiculo.value.marca,
      this.listaMarcas
    );

    const model = this._carService.getItemListaModelo(
      this.formVeiculo.value.model,
      this.listaModelos
    );

    this.dados = {
      ...this.dados,
      veiculo: {
        id: this.dados.veiculo?.id || this._utilService.getCodigo(),
        marca: {
          marcaId: marca.identificadorMarcaVeiculo,
          marcaNome: marca.descricao,
        },
        placa: this.formVeiculo.value.placa.toUpperCase(),
        modelo: {
          modeloId: model.identificadorModeloVeiculo,
          modeloNome: model.descricao,
        },
        anoFabricacao: this.formVeiculo.value.ano,
        categoria: 1,
        dispositivo: this.dados.veiculo?.dispositivo,
        dispositivoCliente: this.dados.veiculo?.dispositivoCliente
      },
    };

    this._localStorageService.criptografarDados(this.dados);
    this._ga4Service.ga4VehicleInfo(this.dados);
  }

  get f(): { [key: string]: any } {
    return this.formVeiculo.controls;
  }
}
