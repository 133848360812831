<div class="modal">
  <div class="modal-content p-8 bg-white w-[630px]">
    <div class="flex flex-row mb-6 justify-between">
      <p class="txt-title text-2xl">{{ data.nome }}</p>

      <ftc-icon-svg [icon]="'closed'" class="cursor-pointer" (click)="modalfechar()"></ftc-icon-svg>
    </div>

    <div class="mb-4">
      <p class="txt-primary font-bold mb-1">Forma de pagamento</p>
      <p *ngFor="let item of data.formasPagamento" class="txt-grafite mb-1.5">
        {{ item }}
      </p>
    </div>

    <div *ngIf="data.descontos.length > 0" class="mb-4">
      <p class="txt-primary font-bold mb-1">Desconto</p>
      <p *ngFor="let item of data.descontos" class="txt-grafite mb-1.5">
        {{ item }}
      </p>
    </div>

    <div class="mb-4">
      <p class="txt-primary font-bold mb-1">Benefícios</p>
      <p *ngFor="let item of data.beneficios" class="txt-grafite mb-4">
        {{ item }}
      </p>
    </div>

    <div class="mb-4">
      <p class="txt-primary font-bold mb-1">Financeiro</p>
      <p *ngFor="let item of data.financeiros" class="txt-grafite mb-1.5">
        {{ item }}
      </p>
    </div>

    <div class="b-top mt-auto pt-6">
      <p class="txt-grafite text-xs font-bold">
        R$ <span class="text-2xl">{{ data.preco }}</span>
        <br>
        {{ data.frequencia }}
      </p>
    </div>
  </div>
</div>