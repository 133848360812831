import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { DadosInterface } from "../models/dados.interface";
import { ApiService } from "./api.service";
import { LocalStorageService } from "./local-storage.service";
import { PagamentoService } from "./pagamento.service";
import { UrlService } from "./url.service";

@Injectable({ providedIn: 'root' })
export class PedidoService {
    private _apiService = inject(ApiService);
    private _localStorageService = inject(LocalStorageService);
    private _pagamentoService = inject(PagamentoService);
    private _urlService = inject(UrlService);

    private dados: DadosInterface = {} as DadosInterface;

    postPedidoCadastrar(payload: any): Observable<any> {
        const url = this._urlService.getApiPedidoCadastrar();

        return this._apiService.post(url, payload);
    }

    getDispositivo(dispositivo: string): string {
        return dispositivo.length === 11 ? dispositivo.substring(0, 10) : dispositivo;
    }
    
    postPedidoFinalizar(payload: any): Observable<any> {
        const url = this._urlService.getApiPedidoFinalizar();

        return this._apiService.post(url, payload);
    }
}