import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconSvgComponent } from '../../components/icon-svg/icon-svg.component';

@Component({
  selector: 'ftc-mensagem',
  templateUrl: './mensagem.component.html',
  standalone: true,
  imports: [IconSvgComponent, NgIf],
})
export class MensagemComponent implements OnInit {
  /* Legenda
    1 = sucesso
    2 = fralde
    3 = recaptcha
    4 = placa já ativa
    5 = pedido existente
    6 = data posterior
    7 = Modal customizado com input dinamico
  */

  @Input() message: string = "";

  @Input() type: number = 0;

  @Output() fecharModal = new EventEmitter();

  ngOnInit(): void { }

  protected modalfechar(): void {
    this.fecharModal.emit();
  }
}
