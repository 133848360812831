<form [formGroup]="formCredit" (ngSubmit)="continuar()">
  <div class="mb-4">
    <label>Número do cartão</label>
    <input type="text" placeholder="Digite o número do cartão de crédito" name="numero"
      mask="0000 0000 0000 000||0000 0000 0000 0000" formControlName="numero"
      [ngClass]="{ 'form--error': submetido && f['numero'].errors }" />
    <div class="form__error" *ngIf="submetido && f['numero'].errors">
      <span *ngIf="f['numero'].errors['invalidEmpty']">Campo obrigatório</span>
      <span *ngIf="f['numero'].errors['invalidCard']">Deve ter de 15 à 16 números</span>
    </div>
  </div>

  <div class="mb-4">
    <label>Nome impresso no cartão</label>
    <input type="text" placeholder="Ex: Maria S Aparecida" name="nome" formControlName="nome"
      [ngClass]="{ 'form--error': submetido && f['nome'].errors }" />
    <div class="form__error" *ngIf="submetido && f['nome'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div class="mb-4 flex flex-col">
    <label>Validade</label>

    <div class="flex gap-x-5">
      <div class="w-1/2">
        <select name="mes" formControlName="mes" [ngClass]="{ 'form--error': submetido && f['mes'].errors }">
          <option value="">Selecione o mês</option>
          <option *ngFor="let item of listaMeses" [value]="item.text">
            {{ item.text }}
          </option>
        </select>
        <div class="form__error" *ngIf="submetido && f['mes'].errors">
          <span>Campo obrigatório</span>
        </div>
      </div>

      <div class="w-1/2">
        <input type="text" placeholder="Ano" mask="0000" name="ano" formControlName="ano"
          [ngClass]="{ 'form--error': submetido && f['ano'].errors }" />
        <div class="form__error" *ngIf="submetido && f['ano'].errors">
          <span *ngIf="f['ano'].errors['invalidEmpty']">Campo obrigatório</span>
          <span *ngIf="f['ano'].errors['invalidYear']">Ano inválido</span>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center">
    <p class="text-xs mb-4">Ao avançar você declara que concorda com os <a [href]="_urlService.contratoAdesao"
        target="_blank" rel="noopener noreferrer" class="txt-link">Termos de adesão</a>. </p>
  </div>

  <div class="text-center">
    <button>Continuar</button>
  </div>
</form>

<ftc-atencao *ngIf="modalAtencao !== 0" [type]="modalAtencao" (fecharModal)="fecharModal()"></ftc-atencao>

<ftc-progresso *ngIf="openModalProgresso"></ftc-progresso>

<ftc-mensagem [type]="7" [message]="responseError" *ngIf="modalCreditValid" (fecharModal)="fecharModal()" ></ftc-mensagem>