<div class="modal">
  <div class="modal-content w-[630px] p-8 flex flex-col items-center">
    <div class="mb-6 flex justify-end w-full">
      <ftc-icon-svg [icon]="'closed'" class="cursor-pointer" (click)="modalfechar()"></ftc-icon-svg>
    </div>

    <div *ngIf="type === 1" class="flex flex-col items-center">
      <ftc-icon-svg [height]="48" [icon]="'success'" class="mr-3.5"></ftc-icon-svg>
      <p class="txt-feedback-title txt-success">Sucesso!</p>
      <p class="txt-feedback-text">
        Sua tagCAIXA foi desbloqueada e <br />está liberada pra uso!
      </p>
    </div>

    <div *ngIf="type === 2" class="flex flex-col items-center">
      <ftc-icon-svg [height]="48" [icon]="'warning'" class="mr-3.5"></ftc-icon-svg>
      <p class="txt-feedback-title txt-warning">Atenção!</p>
      <p class="txt-feedback-text">
        Não foi possivel prosseguir com a <br />ativação de sua Tag.
      </p>
    </div>

    <div *ngIf="type === 3" class="flex flex-col items-center">
      <ftc-icon-svg [height]="48" [icon]="'warning'" class="mr-3.5"></ftc-icon-svg>
      <p class="txt-feedback-title txt-warning">Atenção!</p>
      <p class="txt-feedback-text">
        reCAPTCHA inválido, <br />por favor tente novamente.
      </p>
    </div>

    <div *ngIf="type === 4" class="flex flex-col items-center">
      <ftc-icon-svg [height]="48" [icon]="'warning'" class="mr-3.5"></ftc-icon-svg>
      <p class="txt-feedback-title txt-warning">Atenção!</p>
      <p class="txt-feedback-text">
        Não foi possível continuar. <br />
        Placa já ativa para outro cliente.
      </p>
    </div>


    <div *ngIf="type === 5" class="flex flex-col items-center">
      <ftc-icon-svg [height]="48" [icon]="'warning'" class="mr-3.5"></ftc-icon-svg>
      <p class="txt-feedback-title txt-warning">Atenção!</p>
      <p class="txt-feedback-text">
        Não foi possível continuar. <br />
        Já existe pedido de ativação não <br />atribuído ao cliente com essa
        placa.
      </p>
    </div>

    <div *ngIf="type === 6" class="flex flex-col items-center">
      <ftc-icon-svg [height]="48" [icon]="'warning'" class="mr-3.5"></ftc-icon-svg>
      <p class="txt-feedback-title txt-warning">Atenção!</p>
      <p class="txt-feedback-text">
        A data de validade do cartão deve <br />ser posterior à data atual.
      </p>
    </div>

    <div *ngIf="type === 7" class="flex flex-col items-center">
      <ftc-icon-svg [height]="48" [icon]="'warning'" class="mr-3.5"></ftc-icon-svg>
      <p class="txt-feedback-title txt-warning">Atenção!</p>
      <p class="txt-feedback-text">
       {{message}}
      </p>
    </div>

    <button (click)="modalfechar()" class="btn__expansivel">Fechar</button>
  </div>
</div>