import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppAbstract } from 'src/app/app.abstract';
import { MensagemComponent } from 'src/app/modals/mensagem/mensagem.component';
import { DadosInterface } from 'src/app/models/dados.interface';
import { AtencaoComponent } from '../../../modals/atencao/atencao.component';
import { ProgressoComponent } from '../../../modals/progresso/progresso.component';

@Component({
  selector: 'ftc-cartao-credito',
  templateUrl: './cartao-credito.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgClass,
    NgIf,
    NgFor,
    AtencaoComponent,
    ProgressoComponent,
    MensagemComponent
  ],
})
export class CartaoCreditoComponent extends AppAbstract implements OnInit {
  @Output() creditOutput = new EventEmitter();

  authZeroReturn: boolean = false;
  dados: DadosInterface = {} as DadosInterface;

  modalCreditValid: boolean = false;
  responseError: string = ""

  retry = 0;

  formCredit!: FormGroup;

  openModalTryAgain = false;
  openModalProgresso = false;
  submetido = false;

  banceira: string = '0';

  modalAtencao: number = 0;

  listaMeses: any[] = [];

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.criarFormulario();
  }

  private criarFormulario(): void {
    this.formCredit = this._formBuilder.group({
      numero: [this.dados.pagamento?.cartaoCredito?.numeroCartao || '', this._pagamentoService.validarCartaoCredito],
      nome: [this.dados.pagamento?.cartaoCredito?.nomeImpressoCartao || '', Validators.required],
      mes: [this.dados.pagamento?.cartaoCredito?.mesVencimentoCartao || '', Validators.required],
      ano: [this.dados.pagamento?.cartaoCredito?.anoVencimentoCartao || '', this._pagamentoService.formatarAnoValidade],
    });

    this.getMeses();
  }

  private getMeses(): void {
    this._subSink.sink = this._apiService
      .get(this._urlService.listaMeses)
      .subscribe((data: any) => (this.listaMeses = data.meses));
  }

  protected async continuar(): Promise<void> {
    this.submetido = true;
    
    if (this.formCredit.valid) {
      this.openModalProgresso = true;

      try {
        this.postToken();
      } catch (error) {
        console.error(error);
        this.fecharModal();
      }
    }
  }

  private async postToken(): Promise<void> {
    const payload = this._payloadService.payloadTokeninzing(this.formCredit.value.numero);
   
    let resposta: any;
   
    try {
      resposta = await this._tokenService.postToken(payload).toPromise();
    } catch(error: any) {
      console.log(error?.error.errors[0]);
      this.responseError = error?.error.errors[0];
      this.modalCreditValid = true;
    }

    

    this._logService.setStorageUltimaApi('tokenizing', null, "");

    const { token, additionalInformation } = resposta;

    this.fecharModal();

    if (this._utilService.isNulo(token)) {
      this.banceira = additionalInformation.brand.name;
      this.criptografarTokenizing(token);
    } else {
      this.openModalTryAgain = true;
    }
  }

  private async criptografarTokenizing(tokenizing: string): Promise<void> {
    this.openModalProgresso = true;
    this.dados = this._localStorageService.descriptografarDados();

    this.dados.pagamento = {
      ...this.dados.pagamento,
      cartaoCredito: {
        bandeiraCartao: {
          bandeiraCartaoNome: this.banceira,
          bandeiraCartaoId: this._creditCardService.getCodigoBandeira(this.banceira),
        },
        numeroCartao: this.formCredit.value.numero,
        nomeImpressoCartao: this.formCredit.value.nome,
        mesVencimentoCartao: this.formCredit.value.mes,
        anoVencimentoCartao: this.formCredit.value.ano,
      },
      tokenizing: tokenizing,
      formaPagamento: this.dados.pagamento?.formaPagamento,
    };

    this._localStorageService.criptografarDados(this.dados);
    this._logService.postLog();
    this.postAuthZero();
  }

  private async postAuthZero(): Promise<void> {
    const payload = this._payloadService.payloadAuthZero(this.dados);
    const resposta = await this._authZeroService.postAuthZero(payload).toPromise();

    this._logService.setStorageUltimaApi('pagamento/auth-zero', resposta.status?.codigoRetorno, resposta.status?.mensagem);
    

    
    await this._logService.postLog();

    this.fecharModal();

    if (this._utilService.isNulo(resposta.dados)) {
      if (resposta.dados.mensagem === 'Autorizado') {
        this.creditOutput.emit();
      } else if(resposta?.status?.codigoRetorno == -1) {

        if(this.retry == 2) {
          this.modalAtencao = 6;
        } else {
          this.retry++;
          this.authZeroReturn = true;
        }
       
      } else {
        this.modalAtencao = 6;
      }
    } else {
      this.openModalTryAgain = true;
    }
  }

  protected fecharModal(): void {
    this.modalAtencao = 0;
    this.modalCreditValid = false;
    this.authZeroReturn = false;
    this.openModalTryAgain = this.openModalProgresso = false;
  }

  protected get f(): { [key: string]: any } {
    return this.formCredit.controls;
  }
}
